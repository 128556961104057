/******************************
 FUNZIONI DI BOOKING
 *****************************/

var Cookie = function() {

    var cookieName = 'CuborioCookieAccept';

    var cookieExpireDays = 30;

    var cookieHtmlSelector = '#cookie_alert';

    return {

        init: function () {

            if(!Cookies.enabled || Cookies.get(cookieName)) {
                $(cookieHtmlSelector).addClass('hidden');
            }

        },

        accept: function () {

            $(cookieHtmlSelector).addClass('hidden');

            Cookies.set(cookieName, true, { expires: (cookieExpireDays * 86400) });

        }

    };

}();