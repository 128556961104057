/******************************
 FUNZIONI DI CARRELLO
 *****************************/

var Cart = function() {

    var Variants = {};
    var FormName = 'form#cartForm';

    var setVariant = function (rowId, variant) {

        if(Variants.hasOwnProperty(rowId)) {

            Variants[rowId].push(variant);

        } else {

            Variants[rowId] = [variant];

        }

    };

    var getVariants = function (rowId) {

        return Variants[rowId] || [];

    };

    var bindForm = function () {

        $.validator.addMethod("notEqual", function(value, element, params) {
            var originalValue = $('input[name="'+params+'"]').val();
            return this.optional(element) || value !== originalValue;

        }, function(params, element) {
            return $(element).data('error-message');
        });

        $.validator.addMethod("enableService", function (value, element, params) {
            if(value){
                $('input[name="cart-services-'+params+'"]').prop('disabled', false);
            } else {
                $('input[name="cart-services-'+params+'"]').prop('disabled', true);
            }
            return true;

        }, function(params, element) {
            return $(element).data('error-message');
        });

        // Rules di validazione per i campi dei partecipanti
        Form.instance(FormName).find('input[name^=participants]:visible:text').each(function() {
            FormAjaxValidation.rules[this.name] = {required: true};
        });

        var FormAjaxCallback = function() {

            return {

                onError: function(jqXHR, textStatus, errorMessage) {
                    App.modal('<div class="col-md-12"><h4 class="text-center">' + jqXHR.responseJSON.message + '</h4></div>', jqXHR.responseJSON.title);
                }

            };

        }();

        Form.validate(FormName, FormAjaxValidation, FormAjaxCallback);

    };

    var bindCheckoutButton = function () {
        $('.cart-checkout-button').click(function() {
            $('body,html').animate({scrollTop: Form.instance(FormName).offset().top - 80}, 500);
            Form.instance(FormName).find('input:text:visible:first').focus();
        });
    };

    var bindRemoveButtons = function () {

        $('.removeRow').click(function() {
            var rowId = $(this).closest('.cart-row').data('rowid');
            Cart.remove(rowId);
        });

    };

    var countrySelectChange = function (value) {
        $('.country-toggle').addClass('hide');
        $('.country-' + value).removeClass('hide');
    };

    var bindCountrySelect = function () {

        $("select[name=country_id]").change(function(){
            countrySelectChange($(this).val());
        });

        countrySelectChange($("select[name=country_id]").val());

    };

    var toggleServiceFields = function(checkbox) {

        var service = $(checkbox).data('service');
        var fields = $('.cart-service-fields[data-service="'+service+'"]');

        if(checkbox.checked && $(checkbox).val() === '1') {
            fields.removeClass('hidden');
        } else {
            fields.addClass('hidden');
        }
    };

    var bindServiceSwitches = function () {

        $('.cart-service-button').each(function(){

            $(this).change(function(){
                toggleServiceFields(this);
            });

            toggleServiceFields(this);
        });

    };

    var bindCopyParticipants = function () {

        $('.cart_copy_participants').click(function(){

            var rows = $(this).closest('.row').siblings();
            var names = [];

            $(this).closest('.row').find('input').each(function(index, el){
                names[index] = $(this).val();
            });

            $(rows).each(function(){
                $(this).find('input').each(function(index, el){
                    $(this).val(names[index]);
                });
            });

        });
    };

    var removingItem = function (rowId, stop) {
        var cartRow = $('.cart-row[data-rowid='+rowId+']');
        if(stop) {
            cartRow.addClass('hover-animation');
            cartRow.find('.cart-item-loading').addClass('hide');
        } else {
            cartRow.removeClass('hover-animation');
            cartRow.find('.cart-item-loading').removeClass('hide');
        }
    };

    var removeRow = function (rowId) {
        var cartRow = $('.cart-row[data-rowid='+rowId+']');
        cartRow.remove();
        $('.participants[data-rowid='+rowId+']').remove();
        if(!$('.participants').length){
            $('.participants_title,.participants_step').remove();
        } else if($('.participants').length == 1) {
            $('.cart_copy_participants').remove();
        }
    };

    var checkIfEmpty = function () {

        if($('.cart-row').length == 0) {
            window.location.reload();
        }

    };

    var updateTotal = function (value) {
        $('.totalPrice').html(value);
    };

    var removeItem = function (rowId) {

        var sendEvent = false;
        $.each(getVariants(rowId), function(index, variant) {
            GTM.removeFromCart({
                'id' : variant.code,
                'name' : variant.name,
                'price' : variant.price,
                'brand' : variant.brand,
                'category' : variant.category,
                'variant' : variant.variant,
                'quantity' : variant.quantity
            });
            sendEvent = true;
        });
        if(sendEvent) {
            GTM.send('removeFromCart');
        }

        removingItem(rowId);

        $.ajax({
            type: 'delete',
            url: window.location.href,
            cache: false,
            data: {row: rowId},
            context: this,
            dataType: "json",
            error: function (jqXHR, textStatus, errorMessage) {
                console.log(jqXHR);
            },
            success: function (data) {
                if(data.status === 'success') {
                    removeRow(rowId);
                    checkIfEmpty();
                    updateTotal(data.totalPrice);
                }
            },
            complete: function (jqXHR, textStatus) {
                removingItem(rowId, true);
            }
        });
    };

    var collectCheckoutProducts = function () {

        var sendEvent = false;
        $.each(Variants, function(rowId, item) {
            $.each(item, function(index, el) {
                GTM.checkoutStep({
                    'id' : el.code,
                    'name' : el.name,
                    'price' : el.price,
                    'brand' : el.brand,
                    'category' : el.category,
                    'variant' : el.variant,
                    'quantity' : el.quantity
                });
                sendEvent = true;
            });
        });

        return sendEvent;
    };

    return {

        init: function () {

            Form.init(FormName);

            bindForm();
            bindCheckoutButton();
            bindRemoveButtons();
            bindCountrySelect();
            bindServiceSwitches();
            bindCopyParticipants();

            if(collectCheckoutProducts()) {
                GTM.send('checkout', {
                    step: 1
                });
            }

        },

        remove: function (rowId) {

            removeItem(rowId);

        },

        add: function (rowId, variant) {

            setVariant(rowId, variant);

        },

        purchase: function(url) {

            if(collectCheckoutProducts()) {
                GTM.send('checkout', {
                    step: 2
                });
            }

            $.ajax({
                type: 'get',
                url: url,
                cache: false,
                context: this,
                dataType: "json",
                error: function (jqXHR, textStatus, errorMessage) {
                    console.log(jqXHR, textStatus, errorMessage);
                },
                success: function (data) {
                    window.top.location.href = data;
                }
            });

        }

    };

}();