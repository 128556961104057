/******************************
 FUNZIONI DI GENERICHE FORM
 *****************************/

var Form = function() {

    var Config = {};
    var FormInstances = {};
    var OldFormSubmitText = '';

    var getFormInstance = function (name) {

        return FormInstances[name] || null;

    };

    var FormAjaxValidationDefaults = {
        errorElement: 'div',
        errorClass: 'text-danger bold',
        focusInvalid: true,
        ignore: ":hidden",
        errorPlacement: function(error, element) {
            if (element.is(':checkbox')) {
                error.insertAfter(element.closest("label"));
            } else if (element.is(':radio')) {
                error.insertAfter(element.closest("label"));
            } else {
                error.insertAfter(element); // for other inputs, just perform default behavior
            }
        },
        highlight: function(element) { // hightlight error inputs
            $(element)
                .closest('.form-group').addClass('has-error').removeClass('has-success');
        },
        unhighlight: function(element) { // revert the change done by hightlight
            $(element)
                .closest('.form-group').removeClass('has-error, has-success');
        },
        success: function(label) {
            label
                .closest('.form-group').removeClass('has-error').addClass('has-success');
        },

        submitHandler: function(form, event) {
            if(typeof event !== 'undefined')
            event.preventDefault(); // => con jQuery 1.9 non è necessario, se lo metto si blocca
            var formdata = $(form).serialize();
            var method = $(form).find("input[name=_method]").val() ? $(form).find("input[name=_method]").val() : $(form).attr('method');
            if(typeof FormAjaxCallback !== 'undefined' && typeof FormAjaxCallback.onBefore === "function") {
                FormAjaxCallback.onBefore(form, event);
            } else {
                Form.loading(false, form);
            }
            $.ajax({
                type: method,
                url: $(form).attr('action'),
                cache: false,
                dataType: "json",
                data: formdata,
                error: function (jqXHR, textStatus, errorMessage) {
                    Form.loading(true, form);

                    if(typeof FormAjaxValidator !== 'undefined'){
                        FormAjaxValidator.showErrors(jqXHR.responseJSON);
                    }
                    if(typeof FormAjaxCallback !== 'undefined' && typeof FormAjaxCallback.onError === "function"){
                        FormAjaxCallback.onError(jqXHR, textStatus, errorMessage);
                    } else {
                        Form.AjaxErrorBehaviour(jqXHR.responseJSON);
                    }
                },
                success: function (data) {
                    if(typeof FormAjaxCallback !== 'undefined' && typeof FormAjaxCallback.onSuccess === "function"){
                        FormAjaxCallback.onSuccess(data);
                    } else {
                        Form.AjaxSuccessBehaviour(data);
                    }
                },
                complete: function (jqXHR, textStatus) {
                    if(typeof FormAjaxCallback !== 'undefined' && typeof FormAjaxCallback.onComplete === "function"){
                        FormAjaxCallback.onComplete(jqXHR, textStatus);
                    }
                }
            });
        }
    };

    var setConfig = function (name, options) {

        Config[name] = options || {};

    };

    var formButtonLoading = function (stop, form) {

        var button = $(form).find('[type=submit],[role=submit]');

        if(stop) {
            button.prop('disabled', false);
            button.html(OldFormSubmitText);
        } else {
            OldFormSubmitText = button.html();
            button.prop('disabled', true);
            button.html('<div class="spinner spinner-small"></div> '+LoadingText+'...');
        }

    };

    return {

        init: function(name, options) {

            setConfig(name, options);
            FormInstances[name] = $(name);

        },

        instance: function(name) {

            return getFormInstance(name);

        },

        validate: function(name, FormAjaxValidation, BindFormAjaxCallback) {

            if(typeof FormAjaxValidation === 'undefined'){FormAjaxValidation = {};}
            if(typeof BindFormAjaxCallback !== 'undefined'){FormAjaxCallback = BindFormAjaxCallback;}

            getFormInstance(name).validate($.extend(FormAjaxValidationDefaults, FormAjaxValidation));
        },

        AjaxSuccessBehaviour: function (data) {

            if(typeof data.status !== 'undefined'){
                console.log(data);
            }
            if(data.redirect) {
                window.top.location = data.redirect;
            } else if(data.reload){
                window.location.reload();
            }

        },

        AjaxErrorBehaviour: function (data) {
            console.log(data);
        },

        loading: function(stop, form) {
            formButtonLoading(stop, form);
        }

    }

}();
