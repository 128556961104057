/******************************
 FUNZIONI DI BOOKING
 *****************************/

var Voucher = function() {

    var alertWrapper = $('#voucherAlert');
    var alertContainer = alertWrapper.find('.voucherAlertContainer');
    var alertButton = alertWrapper.find('.voucherAlertButton');
    var alertClose = alertWrapper.find('button.close');

    var handleCheck = function() {

        alertWrapper.hide();

        if (typeof VoucherCheckUrl == "undefined" || !VoucherCheckUrl) { return; }

        if(Session.get('voucherAlert')) {
            Voucher.openAlert();
            return;
        }

        if(Session.get('voucherAlertChecked')) { return; }

        $.ajax(VoucherCheckUrl,{
            method: "GET",
            data: {},
            success: function (response) {
                Session.put('voucherAlertChecked', true);
                if(response) {
                    Session.putObject('voucherAlert', response);
                    Voucher.openAlert();
                }
            }
        });

    };

    var handleOpenAlert = function() {

        var array = Session.get('voucherAlert');

        alertContainer.html(array.message);
        alertButton.html(array.button).addClass('btn-'+array.status);

        alertWrapper
            .addClass('alert-'+array.status)
            .slideDown(500);

        alertClose.click(function(){
            Voucher.closeAlert();
        });
        alertButton.click(function(){
            Voucher.closeAlert();
        });
        Voucher.offsetHeader(alertWrapper.outerHeight());
    };

    var handleCloseAlert = function() {

        Session.forget('voucherAlert');
        Session.put('voucherAlertClosed', true);
        Voucher.offsetHeader(0);
        alertWrapper.slideUp(500);
    };

    var handleHeaderOffsetTop = function (top) {
        $('header').css('top', top+'px');
        $('body').css('padding-top', top+'px');
    };

    return {

        init: function () {

            handleCheck();

        },

        closeAlert: function () {

            handleCloseAlert();

        },

        openAlert: function () {

            handleOpenAlert();

        },

        offsetHeader: function (top) {
            handleHeaderOffsetTop(top);
        }

    }
}();