/******************************
    FUNZIONI DI BOOKING
 *****************************/

var Booking = function() {

    var Config = {};
    var picker;
    var Dates = {};
    var Languages = {};
    var Times = {};
    var SelectedDate;
    var SelectedTime;
    var SelectedLanguage = '';
    var SelectedVariants = {};
    var SelectedProductVariants = {};
    var SelectedSubproductVariants = {};
    var DivId = 'booking_box';
    var Container = '#ProductBookingBox';

    var loadBookingBox = function () {

        if(!$(Container).length || typeof BookingBoxUrl === 'undefined'){ return false; }

        $(Container).html('<div class="uil-ring-css center-block" style="transform:scale(0.5);"><div>');

        $.ajax(BookingBoxUrl,{
            method: "GET",
            data: {},
            success: function (response) {
                $(Container).html(response);
                /*$("aside").theiaStickySidebar({
                    additionalMarginTop: 80
                });*/
                App.initAjax();
            }
        });

    };

    var setConfig = function (options) {

        Config = options;

    };

    var setConfigValue = function (key, value) {

        Config[key] = value;
    };

    var getConfigValue = function (key) {

        return Config[key];

    };

    var unsetConfigValue = function (key) {

        if(Config.hasOwnProperty(key)) {
            delete Config[key];
        }

    };

    var getType = function (value) {

        return getConfigValue('type') === value;

    };

    var BindDatePicker = function () {

        var first = Dates[0];
        var last = Dates[Dates.length-1];

        try {
            picker.destroy()
        } catch(e) {}

        var params = {
            field: $(Config.datePicker)[0],
            format: moment.localeData().longDateFormat('LL'),
            i18n: { months: moment.localeData().months(), weekdays: moment.localeData().weekdays(), weekdaysShort: moment.localeData().weekdaysShort() },
            firstDay: moment().localeData().firstDayOfWeek(),
            minDate: moment().toDate(),
            defaultDate: moment().toDate(),
            maxDate: moment(last).toDate(),
            disableDayFn: function(day) {// non usare day.toISOString().slice(0,10) perché non produce la data esatta perché tiene conto del timezone e non è sicuro
                var isoDate = day.getFullYear()+"-"+("0" + (day.getMonth() + 1)).slice(-2)+"-"+("0" + day.getDate()).slice(-2);
                return (Dates.indexOf(isoDate) === -1);
            },
            onSelect: function() {
                Booking.setDate(this.getMoment().format('YYYY-MM-DD'));

                if(Booking.getType(1)) Booking.getTimes();

                if(Booking.getType(2) && Validate()) Booking.getTimes();

            }
            /*onOpen: function(){
                const el = this.el;
                const { parentNode, style } = el;
                const rect = parentNode.getBoundingClientRect();
                Object.assign(style, {
                    left: `${parseInt(style.left, 10) + parentNode.scrollLeft - rect.left}px`,
                    top: `${parseInt(style.top, 10) + parentNode.scrollTop - rect.top}px`
                });
            }*/
        };

        if(Config.openedCal) {
            params.container = $('.openedCal')[0];
            params.bound = false;
        }

        picker = new Pikaday(params);
        //scroll.appendChild(inst.el);

    };

    var LoadingTimes = function () {
        $('#select-time-placeholder').hide();
        $('#select-time').html('<div class="spinner spinner-dark"></div>');
        hideValidationTooltipError();
    };

    var LoadingLanguages = function () {
        $('#select-language-placeholder').hide();
        $('#select-language').html('<div class="spinner spinner-dark"></div>');
    };

    var BuildLanguageInput = function () {

        var languageDiv = $('#'+DivId+' div[data-validation="language"]');
        var htmlLang = '';
        languageDiv.hide();
        if(Languages.length) {
            languageDiv.show();
            htmlLang = $('<select></select>').attr('name', 'options[language]').attr('data-validation', 'language').addClass('form-control');
            $.each(Languages, function(index, value) {
                $('<option>').val(index).text(value).appendTo(htmlLang);
            });
        }

        $('#select-language').html(htmlLang);
    };

    var BuildTimeInput = function () {

        var htmlTime = '';
        var divCustomSelect = '';
        if(Times) {
            htmlTime = $('<select></select>').attr('name', 'options[time]').addClass('d_none');
            $.each(Times, function(index, value) {
                $('<option>').val(index).text(value).appendTo(htmlTime);
            });
            divCustomSelect = $('<div></div>').addClass('custom_select type_2 fe_width_3 m_top_20 d_inline_b');
            $('<div></div>').addClass('select_title r_corners fs_medium fw_normal color_grey').appendTo(divCustomSelect);
            $('<ul></ul>').addClass('select_list r_corners wrapper shadow_1 bg_light tr_all').appendTo(divCustomSelect);
            htmlTime.appendTo(divCustomSelect);
        }

        $('#select-time').html(divCustomSelect);
        App.initAjax();
    };

    var SelectLanguageChange = function () {

        Booking.unsetTime();
        Booking.removePrices();

        if(getType(2)) {
            Booking.getTimes();
        } else {
            Booking.getDates();
            Booking.unsetDate();
        }

    };

    var BindSelectLanguage = function (gofurther) {

        var languageSelect = $('#'+DivId+' select[data-validation="language"]');

        if(languageSelect.length) {

            Booking.setLanguage(languageSelect.val());

            SelectLanguageChange();

            languageSelect.change(function() {

                Booking.setLanguage($(this).val());

                SelectLanguageChange();

            });

        } else {

            Booking.setLanguage('');

            if(gofurther) {
                SelectLanguageChange();
            }

        }

    };

    var BindSelectTime = function () {

        $('#select-time select').change(function() {

            Booking.setTime($(this).val());

        });

        Booking.setTime();

    };

    var BindSelectVariants = function () {

        $('#'+DivId+' input.productVariantQty').change(function(){
            SelectedProductVariants[$(this).data('variant-id')] = parseInt($(this).val());
            Object.assign(SelectedVariants, SelectedProductVariants);

            if(getType(1) && Validate()) Booking.getPrices();

            if(getType(2)) Booking.getLanguages();

        });

        $('#'+DivId+' input.subproductVariantQty').change(function(){
            SelectedSubproductVariants[$(this).data('variant-id')] = parseInt($(this).val());
            Object.assign(SelectedVariants, SelectedSubproductVariants);

            if(getType(1) && Validate()) Booking.getPrices();

            if(getType(2)) Booking.getLanguages();

        });
    };

    var BindAddToCartButton = function () {

        $('button.add-to-cart').click(function(){
            Booking.addToCart();
        });
    };

    var sumObjectValues = function (obj) {

        var total = 0;
        for(var v in obj){
            total += parseInt(obj[v]);
        }

        return total;
    };

    var getTotalPax = function () {

        return sumObjectValues(SelectedVariants);

    };


    var getProductPax = function () {

        return sumObjectValues(SelectedProductVariants);

    };

    var getSubProductPax = function () {

        return sumObjectValues(SelectedSubproductVariants);

    };

    var mergeConditions = function (condition, scope) {

        var type = Object.keys(condition)[0];
        var related = condition[type] === 'related-instance' ? 'related-instance' : null;
        var value = parseInt(condition[type]);

        if(type === 'greater') {

            Config.QtyRules[scope].min = related || Math.max((value + 1), Config.QtyRules[scope].min);

        } else if(type === 'equals' || type === 'greater-equals') {

            Config.QtyRules[scope].min = related || Math.max(value, Config.QtyRules[scope].min);

        } else if(type === 'less') {

            Config.QtyRules[scope].max = related || Math.min((value - 1), Config.QtyRules[scope].max);

        } else if(type === 'equals' || type === 'less-equals') {

            Config.QtyRules[scope].max = related || Math.min(value, Config.QtyRules[scope].max);

        } else if(type === 'multiple') {

            Config.QtyRules[scope].step = related || Math.min(value, Config.QtyRules[scope].step);

        }

    };

    var walkConditions = function (obj, scope) {

        $.each(obj, function(key, value) {

            var type = Object.keys(value)[key];

            if(type === 'all' || type === 'any') {

                walkConditions(value[type], scope);

            } else {

                mergeConditions(value, scope);

            }

        });

    };

    var applyQuantityRules = function () {

        Config.QtyRules = {
            product: {
                min: 0,
                max: 30,
                step: 1
            }
        };

        applyGlobalQuantityRules();
        applyProductQuantityRules();
        applyProductVariantQuantityRules();
        applySubProductQuantityRules();
        setQuantityFields();

    };

    var applyGlobalQuantityRules = function () {

        walkConditions(Config.globalQuantityRules, 'product'); //ha sempre scope product perché comunque sono a livello di prodotto

    };

    var applyProductQuantityRules = function () {

        walkConditions(Config.productQuantityRules, 'product');

    };

    var applyProductVariantQuantityRules = function () {

        $.each(Config.variantQuantityRules, function (key, rule) {

            var variant_id = Object.keys(rule)[0];

            Config.QtyRules['variant_' + variant_id] = {
                min: 0,
                max: 30,
                step: 1
            };

            walkConditions(rule, 'variant_' + variant_id);

        });

    };

    var applySubProductQuantityRules = function () {

        $.each(Config.subproductQuantityRules, function (key, rule) {

            $.each(rule, function (subproduct_id, condition) {

                Config.QtyRules['variant_' + subproduct_id] = {
                    min: 0,
                    max: 30,
                    step: 1
                };

                walkConditions([condition], 'variant_' + subproduct_id);

            });

        });

    };

    var getVariantAttr = function (variant_id, attr) {

        if(typeof Config.QtyRules['variant_' + variant_id] !== 'undefined') {

            if(Config.QtyRules['variant_' + variant_id][attr] === 'related-instance') {

                return getProductPax();

            }

            return Config.QtyRules['variant_' + variant_id][attr];

        }

        return Config.QtyRules['product'][attr] || null;

    };

    var setQuantityFields = function () {

        $('#'+DivId+' input.productVariantQty, #'+DivId+' input.subproductVariantQty').each(function (key, value) {

            $(this)
                .attr('step', getVariantAttr($(this).data('variant-id'), 'step') || 1 )
                .attr('min', getVariantAttr($(this).data('variant-id'), 'min') || 0 )
                .attr('max', getVariantAttr($(this).data('variant-id'), 'max') || 100 );

        });

    };

    var validateProductPax = function() {

        var pax = getProductPax();

        if(typeof Config.QtyRules !== 'undefined' && (!pax || pax > Config.QtyRules.product.max || pax < Config.QtyRules.product.min || pax % Config.QtyRules.product.step !== 0)) {
            var el = $('[data-validation="pax"]').find('.tooltip');
            var tooltip = el.html();
            var newTooltip = tooltip.replace(':min', Config.QtyRules.product.min).replace(':max', Config.QtyRules.product.max);
            el.html(newTooltip);
            showValidationTooltipError("pax");

            return true;
        }

        return false;

    };

    var validateLanguage = function () {

        var languageSelect = $('#'+DivId+' select[data-validation="language"]');

        if(languageSelect.length && !languageSelect.val()) {

            return true;

        }

        return false;
    };

    var validateVariantPax = function() {

        var failValidation = false;

        $('#'+DivId+' input.productVariantQty, #'+DivId+' input.subproductVariantQty').each(function (key, value) {

            var val = parseInt($(this).val());
            var variant_id = $(this).data('variant-id');

            if(
                typeof Config.QtyRules !== 'undefined'
                && typeof Config.QtyRules['variant_' + variant_id] !== 'undefined'
                && (val > getVariantAttr(variant_id, 'max') || val < getVariantAttr(variant_id, 'min') || val % getVariantAttr(variant_id, 'step') !== 0)
            ) {

                var el = $('[data-validation="variant_' + variant_id + '"]');
                var tooltip = el.html();
                var newTooltip = tooltip.replace(':min', getVariantAttr(variant_id, 'min')).replace(':max', getVariantAttr(variant_id, 'max'));
                el.html(newTooltip);
                showValidationTooltipError("variant_" + variant_id);

                failValidation = true;

            }

        });

        return failValidation;

    };

    var showValidationTooltipError = function(validationId) {

        $('[data-validation="'+validationId+'"]').addClass('tooltip_visible');

    };

    var hideValidationTooltipError = function(validationId) {

        if(typeof validationId === 'undefined') {
            $('[data-validation]').removeClass('tooltip_visible');
        } else {
            $('[data-validation="'+validationId+'"]').removeClass('tooltip_visible');
        }

    };

    var Validate = function () {
        hideValidationTooltipError();

        if(!SelectedDate) {
            showValidationTooltipError("date");
            return false;
        }

        if(getType(1)) {

            if(!SelectedTime) {
                showValidationTooltipError("time");
                return false;
            }

            if(validateVariantPax()) {
                return false;
            }

            if(validateProductPax()) {
                return false;
            }

        } else if(getType(2)) {

            if(validateVariantPax()) {
                return false;
            }

            if(validateProductPax()) {
                return false;
            }
            if(validateLanguage()) {
                return false;
            }
            if(!SelectedTime) {
                showValidationTooltipError("time");
                return false;
            }

        }

        return true;

    };

    var hideTooltips = function () {
        hideValidationTooltipError("date");
        hideValidationTooltipError("time");
        hideValidationTooltipError("pax");
    };

    var buildUrl = function (url) {
        if (Config.tracking_code_value) {
            url += '?' + Config.tracking_code_name + '=' + Config.tracking_code_value;
        }
        return url;
    };

    var bindBookNow = function() {

        $('.show-booking-box').click(function(){
            var $backdrop = $("<div/>").addClass("modal-backdrop fade in");
            $('body').addClass('modal-open').append($backdrop);
            $(Container + ', .hide-booking-box').addClass('opened');
        });

        $('.hide-booking-box').click(function(){
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
            $(Container + ', .hide-booking-box').removeClass('opened');
        });
    };

    return {
//IMPORTANTE!! l'ordine è fondamentale per la corretta inizializzazione dei componenti

        init: function () {

            loadBookingBox();
            bindBookNow();

        },

        refresh: function () {

            $('#'+DivId).remove();

            SelectedDate = null;
            SelectedTime = null;
            SelectedLanguage = '';
            SelectedVariants = {};
            SelectedProductVariants = {};
            SelectedSubproductVariants = {};
            Booking.init();

        },

        start: function (options) {

            setConfig(options);

            Form.init('form#bookingForm');

            BindSelectLanguage(false);
            BindSelectVariants();
            BindAddToCartButton();

            applyQuantityRules();

            this.getDates();

        },

        dates: function() {
            return Dates;
        },

        getType: function(value) {
            return getType(value);
        },

        bindDatePicker: function() {
            BindDatePicker();
        },

        putDates: function(obj) {
            Dates = obj;
        },

        putTimes: function(obj) {
            Times = obj;
            BuildTimeInput();
            BindSelectTime();
        },

        putLanguages: function(obj) {
            Languages = obj;
            BuildLanguageInput();
            BindSelectLanguage(true);
        },

        getDates: function() {

            var data = {product: Config.productId, from: moment().format('YYYY-MM-DD'), to: moment().add(1, 'years').format('YYYY-MM-DD'), language: SelectedLanguage};

            $.ajax(buildUrl(Config.getDatesUrl),{
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                success: function (response) {
                    if(response.length){
                        Booking.putDates(response);
                        Booking.bindDatePicker();
                    } else {
                        Booking.soldOut();
                    }
                }
            });

        },

        setDate: function (date) {
            SelectedDate = date;
            $('#'+DivId+' input[name="options[date]"]').val(date);
            Validate();
        },

        unsetDate: function () {
            SelectedDate = null;
            $('#'+DivId+' input[name="options[date]"]').val('');
            $('#'+DivId+' input[name="cal_date"]').val('');
        },

        setTime: function (time) {

            SelectedTime = time || SelectedTime || $('#select-time select[data-validation="time"]').val();

            $('#select-time select[data-validation="time"]').val(SelectedTime);

            hideValidationTooltipError();

            Booking.getPrices();
        },

        unsetTime: function () {
            SelectedTime = null;
            Times = {};
            $('#select-time-placeholder').show();
            $('#select-time').html('');
        },

        setLanguage: function (locale) {
            SelectedLanguage = locale;
            if(locale) {
                Validate();
            }

        },

        getLanguages: function() {

            LoadingLanguages();

            var data = {product: Config.productId, date: SelectedDate};

            if(getType(2)) data.quantities = SelectedVariants;

            $.ajax(buildUrl(Config.getLanguagesUrl), {
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                success: function (response) {
                    Booking.putLanguages(response);
                },
                error: function() {
                    Booking.soldOut();
                }
            });

        },

        getTimes: function() {

            LoadingTimes();

            var data = {product: Config.productId, date: SelectedDate, language: SelectedLanguage};

            if(getType(2)) data.quantities = SelectedVariants;

            $.ajax(buildUrl(Config.getTimesUrl), {
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify(data),
                success: function (response) {
                    Booking.putTimes(response);
                },
                error: function() {
                    Booking.soldOut();
                }
            });

        },

        getPrices: function() {

            if(getTotalPax() === 0 || !SelectedDate || !SelectedTime){ Booking.removePrices(); return false; }

            var data = {date: SelectedDate, time: SelectedTime, quantities: SelectedVariants};

            $.ajax(buildUrl(Config.getPricesUrl), {
                method: "POST",
                contentType: "application/json",
                dataType: "json",
                cache: false,
                data: JSON.stringify(data),
                success: function (response) {
                    Booking.putPrices(response);
                },
                error: function() {
                    Booking.soldOut();
                }
            });

        },

        putPrices: function(response) {

            setConfigValue('bookingTotal', response.total);

            $('.table_summary .total td:eq(1)').html('€ '+response.total);
            $('.table_summary').parent().removeClass('hidden');

            $('.table_summary tr.partial').remove();
            if(Config.explodingPrices) {
                $.each(response.parts, function (index, part) {
                    var pricePart = $('<tr></tr>').addClass('partial fw_light tr_delay tooltip_container');
                    $('<span></span>').addClass('tooltip top').html(part.description).appendTo(pricePart);
                    $('<td></td>').html(part.name).appendTo(pricePart);
                    $('<td></td>').addClass('t_align_r').html('€ ' + part.value).appendTo(pricePart);

                    $('.table_summary tbody').append(pricePart);
                });
            }
        },

        removePrices: function() {
            unsetConfigValue('bookingTotal');
            $('.table_summary').parent().addClass('hidden');
        },

        soldOut: function() {

            $('#book_now').find('input, select').prop('disabled', true).prop('readonly', true);
            $('#sold_out').removeClass('hidden').height($('#book_now').height());
            hideTooltips();
        },

        addToCart: function() {

            if(Validate()){

                Form.loading(false, 'form#bookingForm');

                var sendEvent = false;
                $("#"+DivId).find('input[name^="variants["]').each(function(index, el) {

                    if(parseInt($(el).val()) > 0 && typeof GTM !== 'undefined') {
                        GTM.addToCart({
                            'id' : Config.productCode,
                            'name' : Config.productName,
                            'price' : getConfigValue('bookingTotal'),
                            'brand' : null,
                            'category' : Config.productCategory,
                            'variant' : $(el).data('variant-name'),
                            'quantity' : $(el).val()
                        });
                        sendEvent = true;
                    }

                });
                if(sendEvent && typeof GTM !== 'undefined'){
                    GTM.send('addToCart');
                }

                $('#'+DivId+' form').submit();
            }

        }
    };

}();